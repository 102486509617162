import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment-timezone';

const okStatus = "OK";
const fetchingStatus = "Fetching from server...";

const toFixed0 = new Intl.NumberFormat(navigator.language, { minimumFractionDigits: 0, maximumFractionDigits: 0, useGrouping: true });
const toFixed1 = new Intl.NumberFormat(navigator.language, { minimumFractionDigits: 1, maximumFractionDigits: 1, useGrouping: true });
const toFixed2 = new Intl.NumberFormat(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true });
const toFixed3 = new Intl.NumberFormat(navigator.language, { minimumFractionDigits: 3, maximumFractionDigits: 3, useGrouping: true });

const About = () =>
{
    const [tle, setTle] = useState({});
    const [serverInfo, setServerInfo] = useState({ status: fetchingStatus });

    const wasmIsSupported = useRef(window.wasmSupported);


    useEffect(() => 
    {
        fetchServerInfo();

        var tleJson = localStorage.getItem("SatelliteSpotter.TLE");

        if (tleJson && tleJson.length > 0)
        {
            setTle(JSON.parse(tleJson));
        }
    },
    []);

    function resetLocations()
    {
        var defaultLocations = [
            { latitude: -33.86785, longitude: 151.20732, name: "Sydney", ISO3166_2: "NSW", adminName1: "New South Wales", ISO3166_1: "AU", countryName: "Australia", timezoneId: "Australia/Sydney" },
            { latitude: 34.05223, longitude: -118.24368, name: "Los Angeles", ISO3166_2: "CA", adminName1: "California", ISO3166_1: "US", countryName: "United States", timezoneId: "America/Los_Angeles" },
            { latitude: 40.78343, longitude: -73.96625, name: "Manhattan", ISO3166_2: "NY", adminName1: "New York", ISO3166_1: "US", countryName: "United States", timezoneId: "America/New_York" },
            { latitude: 51.50853, longitude: -0.12574, name: "London", ISO3166_2: "ENG", adminName1: "England", ISO3166_1: "GB", countryName: "United Kingdom", timezoneId: "Europe/London" },
            { latitude: 28.63576, longitude: 77.22445, name: "New Delhi", ISO3166_2: "DL", adminName1: "Delhi", ISO3166_1: "IN", countryName: "India", timezoneId: "Asia/Kolkata" }
        ];

        var json = JSON.stringify(defaultLocations);

        localStorage.setItem("SatelliteSpotter.Locations", json);

        sessionStorage.setItem("SatelliteSpotter.SelectedSightingIndex", 0);
    }

    async function getServerInfoButtonButton_onClick()
    {
        fetchServerInfo();
    }

    async function fetchServerInfo()
    {
        // if already in error state show fetching...
        if (serverInfo.status !== okStatus)
        {
            setServerInfo({ status: fetchingStatus });
        }

        let timeStart = Date.now();

        try
        {
            const response = await fetch('api/satellite/server-info');

            if (response.status === 200)
            {
                let timeNow = Date.now();

                let fetchedObj = await response.json();

                if (fetchedObj && fetchedObj.serverInfo)
                {
                    let approxFixedDelay = 2; // approximate fixed delay

                    fetchedObj.serverInfo.status = okStatus;
                    fetchedObj.serverInfo.responseTime = timeNow - timeStart;
                    fetchedObj.serverInfo.clientServerTimeDiff = timeNow - fetchedObj.serverUtcTimestamp - approxFixedDelay;

                    setServerInfo(fetchedObj.serverInfo);
                }
                else
                {
                    console.log("Error: fetchServerInfo(): response contained invalid data.");
                    setServerInfo({ status: "Invalid response from server" });
                }
            }
            else
            {
                console.log(`Error: fetchServerInfo(): failed to fetch data from app server. Response status code: ${response.status}`);
                setServerInfo({ status: "Failed to fetch data" });
            }
        }
        catch (err)
        {
            console.log(`Error: fetchServerInfo(): caught exception: ${err.name}: ${err.message}`);
            setServerInfo({ status: `Error: ${err.message}` });
        }
    }

    function durationStr_DHHMMSS(dur)
    {
        // format: D:HH:MM:SS
        var result = `${Math.trunc(dur.asDays()).toFixed(0)}:${dur.hours().toString().padStart(2, '0')}:${dur.minutes().toString().padStart(2, '0')}:${dur.seconds().toString().padStart(2, '0')}`;

        return result;
    }

    function renderClientInfo()
    {
        return (
            <React.Fragment>
                <h2>Client Information</h2>
                <table className="table table-striped details-table">
                    <thead>
                        <tr>
                            <th>Attribute</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr key={0}>
                            <td>WASM Support</td>
                            <td>{wasmIsSupported.current ? "Yes" : "No"}</td>
                        </tr>
                        <tr key={1}>
                            <td>UTC Offset</td>
                            <td>UTC{moment().format("Z")}</td>
                        </tr>
                        <tr key={2}>
                            <td>Satellite Data Fetched</td>
                            <td>{(tle && tle.fetchedTimestamp) ? moment(tle.fetchedTimestamp).format("YYYY-MM-DD HH:mm:ss") : "-"}</td>
                        </tr>
                        <tr key={3}>
                            <td>Satellite Data Expiry</td>
                            <td>{(tle && tle.expiryTimestamp) ? moment(tle.expiryTimestamp).format("YYYY-MM-DD HH:mm:ss") : "-"}</td>
                        </tr>
                    </tbody>
                </table>
            </React.Fragment>
        );
    }

    function renderServerInfo()
    {
        if (serverInfo)
        {
            let statusOK = serverInfo.status === "OK";
            let uptime = moment.duration(Date.now() - serverInfo.applicationStartTime);

            let timeDiffString = "-";

            //let serverDesc = "";
            //if (serverInfo.serverDescription)
            //{
            //    serverDesc = serverInfo.serverDescription.split('\n').map((item, i) => <p key={i}>{item}</p>);
            //}

            if (statusOK)
            {
                let timeDiffSeconds = serverInfo.clientServerTimeDiff / 1000;
                let absTimeDiffSeconds = Math.abs(timeDiffSeconds);

                if (absTimeDiffSeconds < 0.1)
                {
                    timeDiffString = `${toFixed3.format(timeDiffSeconds)} s`;

                    if (timeDiffString === "-0.000 s") timeDiffString = "0.000 s";
                }
                else if (absTimeDiffSeconds < 1)
                {
                    timeDiffString = `${toFixed2.format(timeDiffSeconds)} s`;
                }
                else if (absTimeDiffSeconds < 10)
                {
                    timeDiffString = `${toFixed1.format(timeDiffSeconds)} s`;
                }
                else
                {
                    timeDiffString = `${toFixed0.format(timeDiffSeconds)} s`;
                }
            }

            return (
                <React.Fragment>
                <h2>Server Information</h2>
                <table className="table table-striped details-table">
                    <thead>
                        <tr>
                            <th>Attribute</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr key={0}>
                            <td>Status</td>
                            <td>{serverInfo.status}</td>
                        </tr>
                        <tr key={1}>
                            <td>Description</td>
                            <td>{statusOK ? serverInfo.serverDescription : "-"}</td>
                        </tr>
                        <tr key={2}>
                            <td>Location</td>
                            <td>{statusOK ? serverInfo.serverLocation : "-"}</td>
                        </tr>
                        <tr key={3}>
                            <td>Operating System</td>
                            <td>{statusOK ? serverInfo.operatingSystem : "-"}</td>
                        </tr>
                        <tr key={4}>
                            <td>.Net Version</td>
                            <td>{statusOK ? serverInfo.dotNetVersion : "-"}</td>
                        </tr>
                        <tr key={5}>
                            <td>App Server Version</td>
                                <td>{statusOK ? <span>{serverInfo.applicationVersion}&nbsp;&nbsp;&nbsp;&nbsp;(Build {serverInfo.applicationBuild})</span> : "-"}</td>
                        </tr>
                        <tr key={6}>
                            <td>Build Timestamp</td>
                            <td>{statusOK ? moment(serverInfo.applicationBuildTimestamp).format("YYYY-MM-DD HH:mm:ss") : "-"}</td>
                        </tr>
                        <tr key={7}>
                            <td>Startup</td>
                            <td>{statusOK ? moment(serverInfo.applicationStartTime).format("YYYY-MM-DD HH:mm:ss") : "-"}</td>
                        </tr>
                        <tr key={8}>
                            <td>Uptime</td>
                            <td>{statusOK ? durationStr_DHHMMSS(uptime) : "-"}</td>
                        </tr>
                        <tr key={9}>
                            <td>Server Response Time</td>
                            <td>{statusOK ? `${toFixed0.format(serverInfo.responseTime)} ms` : "-"}</td>
                        </tr>
                        <tr key={10}>
                            <td>Client/Server Clock Diff</td>
                                <td>{statusOK ? timeDiffString : "-"}</td>
                        </tr>
                        <tr key={11}>
                            <td>Satellite Data Sourced</td>
                                <td>{(statusOK && tle && tle.sourcedTimestamp) ? moment(tle.sourcedTimestamp).format("YYYY-MM-DD HH:mm:ss") : "-"}</td>
                        </tr>
                    </tbody>
                </table>
                </React.Fragment>
            );
        }
        else
        {
            return null;
        }
    }

    // <button id="clear-location-cache-button" onClick={resetLocations} > Reset Locations List</button > <br />

    return (
        <div>
            <h1>About This Web Application</h1>
            <div id="server-info">
                {renderClientInfo()}
                {renderServerInfo()}
                <button id="server-info-button" type="button" className="btn btn-info" onClick={getServerInfoButtonButton_onClick}>Update Server Information</button>
            </div>
            <p className="center-text">email:&ensp;<a className="black-text" href="mailto:support@satellitespotter.com">support@satellitespotter.com</a></p>            
        </div>
    );
}

export default About;
