import React, { useState, useEffect } from 'react';
import Combobox from 'react-widgets/lib/Combobox';
import $ from 'jquery'
import 'react-widgets/dist/css/react-widgets.css';

function renderForecastsTable(forecasts)
{
    return (
        <table className='table table-striped'>
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Temp. (C)</th>
                    <th>Temp. (F)</th>
                    <th>Summary</th>
                </tr>
            </thead>
            <tbody>
                {forecasts.map(forecast =>
                    <tr key={forecast.forecastDate}>
                        <td>{(new Date(forecast.forecastDate)).toLocaleDateString("en-AU")}</td>
                        <td>{forecast.temperatureC}</td>
                        <td>{forecast.temperatureF}</td>
                        <td>{forecast.summary}</td>
                    </tr>
                )}
            </tbody>
        </table>
    );
}

function fullPlaceName(location)
{
    var result = "";

    if (location.hasOwnProperty("name") && typeof location.name === "string" && location.name.length > 0)
    {
        result = location.name;
    }

    if (location.hasOwnProperty("adminName1") && typeof location.adminName1 === "string" && location.adminName1.length > 0)
    {
        result = result.concat((result.length > 0 ? ", " : ""), location.adminName1);
    }

    if (location.hasOwnProperty("countryName") && typeof location.countryName === "string" && location.countryName.length > 0)
    {
        result = result.concat((result.length > 0 ? ", " : ""), location.countryName);
    }

    return result;
}

function renderSightingsTable(sightings)
{
    return (
        <table className='table table-striped'>
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Start</th>
                    <th>Closest</th>
                    <th>End</th>
                    <th>Duration</th>
                </tr>
            </thead>
            <tbody>
                {sightings.map(sighting =>
                    <tr key={sighting.TimeStart}>
                        <td>{(new Date(sighting.TimeStart * 1000)).toLocaleDateString("en-AU")}</td>
                        <td>{(new Date(sighting.TimeStart * 1000)).toLocaleTimeString("en-AU")}</td>
                        <td>{(new Date(sighting.TimeClosest * 1000)).toLocaleTimeString("en-AU")}</td>
                        <td>{(new Date(sighting.TimeEnd * 1000)).toLocaleTimeString("en-AU")}</td>
                        <td>{sighting.VisibleDuration}</td>
                    </tr>
                )}
            </tbody>
        </table>
    );
}


const CurrentLocation = () =>
{
    /*

    const [loading, setLoading] = useState(true);
    const [issPosition, setIssPosition] = useState("");
    const [forecasts, setForecasts] = useState([]);
    const [fetchingWeatherMsg, setFetchingWeatherMsg] = useState("");
    const [input, setInput] = useState('ISS'); // '' is the initial state value
    const [wasmSupported, setWasmSupported] = useState(true);
    const [selectedIndex, setSelectedIndex] = useState("");
    const [doWork, setDoWork] = useState(0);
    const [locations, setLocations] = useState([]);


    /*
    function incrementAge()
    {
        console.log('incrementAge() called.');

        var newAge = age + 9;
        setAge(newAge % 100);
    }
    * /

    function initialiseLocations()
    {
        var defaultLocationCache = [
            { latitude: -33.86785, longitude: 151.20732, name: "Sydney", adminName1: "New South Wales", countryName: "Australia", timezoneId: "Australia/Sydney" },
            { latitude: 34.05223, longitude: -118.24368, name: "Los Angeles", adminName1: "California", countryName: "United States", timezoneId: "America/Los_Angeles" },
            { latitude: 40.78343, longitude: -73.96625, name: "Manhattan", adminName1: "New York", countryName: "United States", timezoneId: "America/New_York" },
            { latitude: 51.50853, longitude: -0.12574, name: "London", adminName1: "England", countryName: "United Kingdom", timezoneId: "Europe/London" },
            { latitude: 28.63576, longitude: 77.22445, name: "New Delhi", adminName1: "Delhi", countryName: "India", timezoneId: "Asia/Kolkata" }
        ];

        setLocations(defaultLocationCache);
    }

    useEffect(() => 
    {
        console.log('useEffect([]) called.');

        initialiseLocations();

        if (loading)
        {
            if (typeof WebAssembly !== 'object')
            {
                setWasmSupported(false);
            }

            populateWeatherData();
        }
    }, []);

    useEffect(() => 
    {
        console.log('useEffect([doWork]) called.');

        //AddNewCoordinateToCombo();

    }, [doWork]);

    useEffect(() => 
    {
        console.log('useEffect([locations]) called.');

        if (locations.length > 0)
        {
            SelectComboIndex(0);
        }

    }, [locations]);

    async function populateWeatherData() 
    {
        setLoading(false);

        console.log('populateWeatherData() called.');

        //        setFetchingWeatherMsg("Fetching...");
        const response = await fetch('api/satellite/weather-forecasts');

        if (response.status === 200)
        {
            try
            {
                //                setFetchingWeatherMsg("Processing response...");
                const data = await response.json();

                if (data != null)
                {
                    setForecasts(data);
                    setFetchingWeatherMsg("");
                }
                else
                {
                    setFetchingWeatherMsg("Error: response contained no data.");
                }
            }
            catch (err)
            {
                setFetchingWeatherMsg(`Error: caught exception: ${err.name}: ${err.message}`);
            }
        }
        else
        {
            console.log(`populateWeatherData() Error: failed to fetch data from app server. Response status code: ${response.status}`);
            setFetchingWeatherMsg(`Error: failed to fetch data from app server. Response status code: ${response.status}`);
        }
    }

    async function getIssPosition()
    {
        setIssPosition("Fetching...");
        const response = await fetch("api/satellite/iss-current-location");

        if (response.status === 200)
        {
            setIssPosition("Processing response...");
            const data = await response.json();

            if (data != null && data.message === "success")
            {
                let timestamp = new Date(data.timestamp * 1000);  // unix num seconds * 1000 to get millisecs since 1970

                setIssPosition(`Time: ${timestamp.toLocaleString()},  GPS position: ${data.iss_position.latitude}, ${data.iss_position.longitude}`);
            }
            else
            {
                setIssPosition("Error: failed to fetch data from remote ISS data server. ");
            }
        }
        else
        {
            console.log(`getIssPosition() Error: failed to fetch data from app server. Response status code: ${response.status}`);
            setIssPosition(`Error: failed to fetch data from app server. Response status code: ${response.status}`);
        }
    }

    function ClearFields()
    {
        setFetchingWeatherMsg("");
        setIssPosition("");
    }

    function GetValue()
    {
        SelectComboIndex(3);
    }

    function SelectComboIndex(index)
    {
        var e = document.getElementById("locations-combobox");
        var value = e.options[index].value;

        $("#locations-combobox").prop('selectedIndex', index);
        $("#coordinates").prop('value', value);
    }

    function TownComboOnChange()
    {
        document.getElementById("coordinates").value = document.getElementById("locations-combobox").value;

        setDoWork(() => doWork + 1);
    }

    function CoordinatesOnChange()
    {
        $("#locations-combobox").prop('selectedIndex', -1);
    }

    function BlankCombo()
    {
        $("#locations-combobox").prop('selectedIndex', -1);
    }

    function AddNewCoordinateToCombo()
    {

    }

    function coordinatesOnKeyDown(event)
    {
        if (event.keyCode === 13 || event.charCode === 13)
        {
            event.preventDefault();
            //event.stopPropagation();

            setDoWork(() => doWork + 1);
        }
        else
        {
            $("#locations-combobox").prop('selectedIndex', -1);
        }
    }

    function AddLocation()
    {
        setLocations(prevLocations =>
        {
            var copy = [];
            Object.assign(copy, prevLocations);

            // add new item to start of array
            //prevLocations.unshift(Object.assign({}, { 'latitude': latitude, 'longitude': longitude }, obj));

            copy.unshift({ latitude: 37.331836, longitude: -122.029628, name: "Cupertino", adminName1: "California", countryName: "United States" });

            return copy;
        });
    }

    function setFocus()
    {
        //document.getElementById("locations-combobox2").focus();

        var ele = document.getElementById("locations-combobox2");
    }

    let contents = loading ? <p><em>Loading...</em></p> : renderForecastsTable(forecasts);
    let showWasmSupport = wasmSupported ? "True" : "False";

    let people = [{ firstName: "John", lastName: "Lennon" },
        { firstName: "Paul", lastName: "McCartney" },
        { firstName: "George", lastName: "Harrison" },
        { firstName: "Ringo", lastName: "Starr" }];

    let ListItem = ({ item }) => (
        <span>
            <strong>{item.firstName}</strong>
            {" " + item.lastName}
        </span>
    );

    let ListItem2 = ({ item }) => (
        <span>
            <strong>{item.firstName}</strong>
            {" " + item.lastName}
        </span>
    );

    var MyCombo = (<Combobox id="location-combobox" data={people} textField={item => typeof item === 'string' ? item : item.lastName + '-' + item.firstName} itemComponent={ListItem} />);

    function renderLocationsCombo(locations)
    {
        /*
        <select id="locations-combobox" className="input-field" name="towns" onChange={TownComboOnChange}>
            <option value="-33.86785, 151.20732">Sydney, New South Wales, Australia</option>
            <option value="34.05223, -118.24368">Los Angeles, California, United States</option>
            <option value="40.78343, -73.96625">Manhattan, New York, United States</option>
            <option value="51.50853, -0.12574">London, England, United Kingdom</option>
            <option value="28.63576, 77.22445">New Delhi, Delhi, India</option>
        </select>
        * /
        return (
            <select id="locations-combobox" className="input-field" name="towns" onChange={TownComboOnChange}>
                {locations.map((location, index) =>
                    <option key={index} value={`${location.latitude}, ${location.longitude}`}>{fullPlaceName(location)}</option>
                )}
            </select>);
    }

    function myClick(event)
    {
        console.log("myClick(event)");
    }

    function myDoubleClick(event)
    {
        console.log(`myDoubleClick(event): index: ${event.target.index}`);
    }
    */
    return (
        <div>
            <h1>Current Location of the ISS</h1>
            <br/>
            <p>Coming soon.</p>
        </div>
    );
}

export default CurrentLocation;

/*

var e = document.getElementById("ddlViewBy");
var val1 = e.options[e.selectedIndex].value;
var txt = e.options[e.selectedIndex].text;

//{ contents }
//{ resultStr } <br />

             <select id="locations-combobox" className="input-field" name="towns" onChange={TownComboOnChange}>
                    <option value="-33.86785, 151.20732">Sydney, New South Wales, Australia</option>
                    <option value="34.05223, -118.24368">Los Angeles, California, United States</option>
                    <option value="40.78343, -73.96625">Manhattan, New York, United States</option>
                    <option value="51.50853, -0.12574">London, England, United Kingdom</option>
                    <option value="28.63576, 77.22445">New Delhi, Delhi, India</option>
                </select>



    // old render code
    return (
            <p>This component demonstrates fetching data from the server.&nbsp;&nbsp;&nbsp;&nbsp;WASM Supported: {showWasmSupport}</p>
            <label>Satellite:</label>&nbsp;
            <input defaultValue={input} onInput={e => setInput(e.target.value)} /><span>&nbsp;&nbsp;&nbsp;&nbsp;
            <button className="btn btn-primary" onClick={populateWeatherData}>Reload data</button>&nbsp;&nbsp;&nbsp;{fetchingWeatherMsg}</span><br /><br />
            <p />
            <span>
                <button className="btn btn-primary" onClick={getIssPosition}>Get current position of ISS</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{issPosition}
            </span>
            <br />
            {contents}
            <input type="text" id="coordinates" className="input-field" onKeyDown={coordinatesOnKeyDown} onChange={CoordinatesOnChange} placeholder="latitude, longitude" />
            <br />
            {renderLocationsCombo(locations)}
            <br />&nbsp;&nbsp;{selectedIndex}&nbsp;&nbsp;<p>Work: {doWork}</p>
            <br />
            <button className="btn btn-primary" onClick={setFocus}>Set Focus</button>
            <br />
            <select multiple id="locations-combobox2" style={{ height: "auto" }} className="input-field" size="8" name="towns" onChange={TownComboOnChange} onDoubleClick={myDoubleClick}>
                <option value="-33.86785, 151.20732">Sydney, New South Wales, Australia</option>
                <option value="34.05223, -118.24368">Los Angeles, California, United States</option>
                <option value="40.78343, -73.96625">Manhattan, New York, United States</option>
                <option value="51.50853, -0.12574">London, England, United Kingdom</option>
                <option value="28.63576, 77.22445">New Delhi, Delhi, India</option>
            </select>
            <br />
            <span>
                <button className="btn btn-primary" onClick={ClearFields}>Clear Fields</button>
                <button className="btn btn-primary" onClick={GetValue}>Get Value</button>
                <button className="btn btn-primary" onClick={BlankCombo}>Blank</button>
                <button className="btn btn-primary" onClick={AddLocation}>Add Location</button>
            </span>
            )
 */